var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var style = "";
const classToggle = (element, htmlClass) => {
  element.classList.toggle(htmlClass);
};
const clickOutside = (element, cb, exceptions) => {
  if (!element) {
    return;
  }
  document.addEventListener("click", (e) => {
    const isClicked = element.contains(e.target) || (exceptions == null ? void 0 : exceptions.some((el) => el.contains(e.target)));
    if (!isClicked) {
      cb();
    }
  });
};
function randomId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}
class Component {
  constructor(attr) {
    __publicField(this, "_attr");
    __publicField(this, "_elements", {});
    this._attr = attr;
    const elements = document.querySelectorAll(`[${attr}]`);
    elements.forEach((element) => {
      this.init(element);
    });
  }
  init(element) {
    const id = randomId();
    element.setAttribute("data-id", id);
    this._elements[id] = element;
  }
  remove(id) {
    delete this._elements[id];
  }
}
class Dropdown extends Component {
  constructor() {
    super("gc-dropdown");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    element.addEventListener("click", () => {
      classToggle(element, "gc-dropdown--open");
    });
    clickOutside(element, () => element.classList.remove("gc-dropdown--open"));
  }
}
var _dropdown = new Dropdown();
class DropdownBtn extends Component {
  constructor() {
    super("gc-dropdown-btn");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    element.addEventListener("click", () => {
      classToggle(element, "gc-dropdown-btn--open");
    });
    clickOutside(element, () => element.classList.remove("gc-dropdown-btn--open"));
  }
}
var _dropdownBtn = new DropdownBtn();
const createElement = (tag, classList) => {
  const el = document.createElement(tag);
  if (classList.length > 0) {
    el.className = classList.join(" ");
  }
  return el;
};
const createAlert = ({
  color = "red",
  content = "",
  closable = false,
  size = "medium"
}) => {
  const alert = createElement("div", [
    "gc-alert",
    ...!!color ? [
      `gc-bg-light${color.charAt(0).toUpperCase() + color.slice(1)} gc-text--color-${color}`
    ] : [],
    ...closable ? ["gc-alert--closable"] : [],
    ...!!size ? [`gc-alert--size-${size}`] : []
  ]);
  alert.setAttribute("gc-alert", "");
  const alertContent = createElement("div", ["gc-alert__content"]);
  alertContent.innerHTML = `<span>${content}</span>`;
  alert.appendChild(alertContent);
  if (closable) {
    alertContent.innerHTML += `<button class="gc-alert__close"><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.2931 6.29326C18.6446 5.94178 18.6446 5.37194 18.2931 5.02046C17.9417 4.66899 17.3718 4.66899 17.0203 5.02046L18.2931 6.29326ZM5.70663 16.3342C5.35516 16.6856 5.35516 17.2555 5.70663 17.607C6.05811 17.9584 6.62795 17.9584 6.97943 17.607L5.70663 16.3342ZM17.0203 5.02046L5.70663 16.3342L6.97943 17.607L18.2931 6.29326L17.0203 5.02046Z" fill="currentColor"/>
    <path d="M6.97966 5.02046C6.62819 4.66899 6.05834 4.66899 5.70687 5.02046C5.35539 5.37194 5.35539 5.94178 5.70687 6.29326L6.97966 5.02046ZM17.0206 17.607C17.372 17.9584 17.9419 17.9584 18.2934 17.607C18.6448 17.2555 18.6448 16.6856 18.2934 16.3342L17.0206 17.607ZM5.70687 6.29326L17.0206 17.607L18.2934 16.3342L6.97966 5.02046L5.70687 6.29326Z" fill="currentColor"/>
    </svg></button>`;
  }
  return alert;
};
class Alert extends Component {
  constructor() {
    super("gc-alert");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
    document.addEventListener("click", (e) => this.close(e.target));
  }
  close(target) {
    const alerts = document.querySelectorAll(`[${this._attr}]`);
    alerts.forEach((alert) => {
      const alertCloseBtn = alert.querySelector(".gc-alert__close");
      if (alertCloseBtn == null ? void 0 : alertCloseBtn.contains(target)) {
        alert.remove();
      }
    });
  }
  initListeners(element) {
    var _a;
    (_a = element.querySelector(".gc-alert__close")) == null ? void 0 : _a.addEventListener("click", () => {
      const id = element.getAttribute("data-id");
      if (id) {
        this.remove(id);
      }
      element.remove();
    });
  }
  show(parentElement, options) {
    const {
      color = "red",
      content = "",
      closable = true,
      size = "medium"
    } = options;
    const alert = createAlert({ color, content, closable, size });
    parentElement.append(alert);
  }
}
var _alert = new Alert();
class Accordion extends Component {
  constructor() {
    super("gc-accordion");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    const header = element.querySelector(".gc-accordion__header");
    header == null ? void 0 : header.addEventListener("click", () => {
      header.toggleAttribute(`aria-expanded`);
    });
  }
}
var _accordion = new Accordion();
class Modal extends Component {
  constructor() {
    super("gc-modal");
    __publicField(this, "_container");
    this._container = createElement("div", ["gc-modals-container"]);
    document.body.appendChild(this._container);
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
      this._container.appendChild(element);
    });
    this.initAttrTriggers();
  }
  initListeners(element) {
    const content = element.querySelector(".gc-modal__content");
    if (content) {
      clickOutside(content, () => {
        this.close(`#${element.id}`);
      }, [
        ...Array.from(document.querySelectorAll("[gc-modal-show]")),
        ...Array.from(document.querySelectorAll("[gc-modal-close]")),
        ...Array.from(document.querySelectorAll("[gc-modal-toggle]"))
      ]);
    }
    const insideCloseBtns = element.querySelectorAll(".gc-modal__close-btn");
    insideCloseBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        this.close(`#${element.id}`);
      });
    });
  }
  initAttrTriggers() {
    const showBtns = document.querySelectorAll("[gc-modal-show]");
    const closeBtns = document.querySelectorAll("[gc-modal-close]");
    const toggleBtns = document.querySelectorAll("[gc-modal-toggle]");
    showBtns.forEach((btn) => {
      const selector = btn.getAttribute("gc-modal-show");
      btn.addEventListener("click", () => {
        this.show(selector);
      });
    });
    closeBtns.forEach((btn) => {
      const selector = btn.getAttribute("gc-modal-close");
      btn.addEventListener("click", () => {
        if (selector) {
          this.close(selector);
        } else {
          const modals = document.querySelectorAll(`[${this._attr}]`);
          modals.forEach((modal) => {
            if (modal.contains(btn)) {
              this.close(`#${modal.id}`);
            }
          });
        }
      });
    });
    toggleBtns.forEach((btn) => {
      const selector = btn.getAttribute("gc-modal-toggle");
      btn.addEventListener("click", () => {
        this.toggle(selector);
      });
    });
  }
  show(uniqueSelector) {
    const modal = document.querySelector(uniqueSelector);
    modal == null ? void 0 : modal.classList.add("gc-modal--show");
    document.body.classList.add("gc-overflow-hidden");
  }
  close(uniqueSelector) {
    const modal = document.querySelector(uniqueSelector);
    if (modal && modal.classList.contains("gc-modal--show")) {
      modal == null ? void 0 : modal.classList.remove("gc-modal--show");
      document.body.classList.remove("gc-overflow-hidden");
    }
  }
  toggle(uniqueSelector) {
    const modal = document.querySelector(uniqueSelector);
    if (modal && modal.classList.contains("gc-modal--show")) {
      this.close(uniqueSelector);
    } else {
      this.show(uniqueSelector);
    }
  }
}
var _modal = new Modal();
const addListener = (element, event, action) => {
  if (Array.isArray(element)) {
    element = element.filter(Boolean);
    element.forEach((el) => el.addEventListener(event, action));
    return;
  }
  element.addEventListener(event, action);
};
class Menu {
  constructor() {
    __publicField(this, "sideMenu");
    __publicField(this, "header");
    this.header = document.querySelector("[gc-header]");
    this.sideMenu = document.querySelector("[gc-side-menu]");
    this.initListeners();
  }
  toggle() {
    if (this.sideMenu) {
      if (this.sideMenu.classList.contains("gc-side-menu--open")) {
        this.close();
      } else {
        this.open();
      }
    }
  }
  close() {
    if (this.sideMenu && this.sideMenu.classList.contains("gc-side-menu--open")) {
      this.sideMenu.classList.remove("gc-side-menu--open");
      document.body.classList.remove("gc-overflow-hidden");
    }
  }
  open() {
    if (this.sideMenu && !this.sideMenu.classList.contains("gc-side-menu--open")) {
      this.sideMenu.classList.add("gc-side-menu--open");
      document.body.classList.add("gc-overflow-hidden");
    }
  }
  initListeners() {
    var _a, _b, _c;
    const hamburgerBtn = (_a = this.header) == null ? void 0 : _a.querySelector(".gc-header__hamburger");
    const closeSideMenuBtn = (_b = this.sideMenu) == null ? void 0 : _b.querySelector(".gc-side-menu__btn");
    const sideMenuContainer = (_c = this.sideMenu) == null ? void 0 : _c.querySelector(".gc-side-menu__container");
    addListener([hamburgerBtn, closeSideMenuBtn], "click", () => this.toggle());
    clickOutside(sideMenuContainer, () => this.close(), [hamburgerBtn]);
  }
}
var _menu = new Menu();
class Select extends Component {
  constructor() {
    super("gc-select");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    element.addEventListener("click", () => {
      classToggle(element, "gc-select--open");
    });
    clickOutside(element, () => element.classList.remove("gc-select--open"));
    const selectedValue = element.querySelector(".gc-select__selected-value");
    const listItems = element.querySelectorAll(".gc-select__list li");
    listItems == null ? void 0 : listItems.forEach((item) => {
      addListener(item, "click", () => {
        selectedValue.innerHTML = item.innerHTML;
      });
    });
  }
}
var _select = new Select();
class SelectGroup extends Component {
  constructor() {
    super("gc-select-group");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    const selected = element.querySelector(".gc-select-group__selected");
    selected == null ? void 0 : selected.addEventListener("click", () => {
      classToggle(element, "gc-select-group--open");
    });
    clickOutside(element, () => element.classList.remove("gc-select-group--open"));
    const items = element.querySelectorAll(".gc-select-group-item");
    items.forEach((item) => {
      const header = item.querySelector(".gc-select-group-item__title");
      header == null ? void 0 : header.addEventListener("click", () => {
        item.classList.toggle("gc-select-group-item--open");
      });
    });
  }
}
var _selectGroup = new SelectGroup();
const tokens = {
  "#": { pattern: /\d/ },
  S: { pattern: /[a-zA-Z]/ },
  A: { pattern: /[0-9a-zA-Z]/ },
  U: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
  L: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() }
};
class Input extends Component {
  constructor() {
    super("gc-input");
    Object.values(this._elements).forEach((element) => {
      this.initListeners(element);
    });
  }
  initListeners(element) {
    element.addEventListener("input", (e) => {
      const mask = e.target.attributes["mask"];
      if (mask) {
        const currentValue = this.applyMask(e.target.value, mask.value);
        e.target.value = currentValue;
      }
    });
  }
  applyMask(value, mask, masked = true) {
    value = value || "";
    let iMask = 0;
    let iValue = 0;
    let output = "";
    while (iMask < mask.length && iValue < value.length) {
      let cMask = mask[iMask];
      let masker = tokens[cMask];
      let cValue = value[iValue];
      if (masker) {
        if (masker.pattern.test(cValue)) {
          output += masker.transform ? masker.transform(cValue) : cValue;
          iMask++;
        }
        iValue++;
      } else {
        if (masked)
          output += cMask;
        if (cValue === cMask)
          iValue++;
        iMask++;
      }
    }
    return output;
  }
}
var _input = new Input();
var components = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  [Symbol.toStringTag]: "Module",
  Dropdown: _dropdown,
  DropdownBtn: _dropdownBtn,
  Alert: _alert,
  Accordion: _accordion,
  Modal: _modal,
  Menu: _menu,
  Select: _select,
  SelectGroup: _selectGroup,
  Input: _input
});
class GcLib {
  constructor(name = "$gclib") {
    __publicField(this, "_instance");
    this._instance = components;
    window[name] = this._instance;
  }
}
export { GcLib };

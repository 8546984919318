import { createApp } from 'vue'
// import { Button } from '../../assets/vendor/giftery-ui/dist/giftery-ui.common'

// import UIkit from 'uikit'
// import Icons from 'uikit/dist/js/uikit-icons'

// UIkit.use(Icons)
// UIkit.container = '#app'

// import Header from './components/layout/Header.vue'

import * as Sentry from '@sentry/vue'

const app = createApp({
  components: {
    // 'ui-button': Button
    // 'layout-header': Header
  }
})

Sentry.init({
  app,
  dsn: 'https://3b47a051736f457ab1cc327c7143251d@o248366.ingest.sentry.io/4504519122812928',
  allowUrls: [/.+\.giftery\..+\//, /.+\.gifterycard\..+\//, /.+\.lvh\.me\//, /.+\.lb\//],
  environment: window.env
});

app.mount('#app')

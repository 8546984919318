// TODO: Ограничивает доступ по ссылке
/* document.addEventListener('DOMContentLoaded', event => {
  const qrScannerVideoBtn = document.querySelector('#qrScannerVideoBtn')
  const qrCodeScannerContainer = document.querySelector('#qrCodeScannerContainer')

  if (qrScannerVideoBtn && qrCodeScannerContainer) {
    const urlParams = new URLSearchParams(window.location.search)
    const qrScanner = urlParams.get('qr-scanner')

    if (qrScanner && qrScanner === 'allow') {
      qrScannerVideoBtn.style.display = 'flex'
      qrCodeScannerContainer.style.display = 'flex'
    } else {
      qrScannerVideoBtn.style.display = 'none'
      qrCodeScannerContainer.style.display = 'none'
    }
  }
}) */

const
  qrScannerFileInput = document.querySelector('#qrScannerFileInput'),
  qrScannerImgBtn = document.querySelector('#qrScannerImgBtn'),
  qrScannerVideoBtn = document.querySelector('#qrScannerVideoBtn'),
  qrScannerVideoBtnCancel = document.querySelector('#qrScannerVideoBtnCancel'),
  qrScannerCanvas = document.querySelector('#qrScannerCanvas'),
  qrScannerImg = document.querySelector('#qrScannerImg'),
  qrScannerVideo = document.querySelector('#qrScannerVideo'),
  usingOffscreenCanvas = isOffscreenCanvasWorking();

let
  offCanvas,
  requestId = null,
  currentStream = null,
  resultQrCode = '';


function isOffscreenCanvasWorking() {
  try {
    return Boolean((new OffscreenCanvas(1, 1)).getContext('2d'))

  } catch {
    return false
  }
}


function formatNumber(number, fractionDigits = 1) {
  return number.toLocaleString(
    undefined, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  )
}


function detect(source) {
  const canvas = qrScannerCanvas
  const ctx = canvas.getContext('2d')

  function getOffCtx2d(width, height) {
    if (usingOffscreenCanvas) {
      if (!offCanvas || (offCanvas.width !== width) || (offCanvas.height !== height)) {
        // Only resizing the canvas caused Chromium to become progressively slower
        offCanvas = new OffscreenCanvas(width, height)
      }

      return offCanvas.getContext('2d')
    }
  }

  canvas.width = source.naturalWidth || source.videoWidth || source.width
  canvas.height = source.naturalHeight || source.videoHeight || source.height

  if (canvas.height && canvas.width) {
    const offCtx = getOffCtx2d(canvas.width, canvas.height) || ctx

    offCtx.drawImage(source, 0, 0)

    const imageData = offCtx.getImageData(0, 0, canvas.width, canvas.height)

    return zbarWasm
      .scanImageData(imageData)
      .then(symbols => {
        symbols.forEach(symbol => {
            const lastPoint = symbol.points[symbol.points.length - 1]
            ctx.moveTo(lastPoint.x, lastPoint.y)
            symbol.points.forEach(point => ctx.lineTo(point.x, point.y))

            ctx.lineWidth = Math.max(Math.min(canvas.height, canvas.width) / 100, 1)
            ctx.strokeStyle = '#00e00060'
            ctx.stroke()
          })

          symbols.forEach(s => s.rawValue = s.decode('utf-8'))

          // console.log(JSON.stringify(symbols, null, 2))

          const value = symbols.find(item => item.rawValue !== '')

          if (value && value.rawValue) {
            const fieldResult = document.querySelector('#checkcertificateform-code')

            resultQrCode = value.rawValue
            fieldResult.value = resultQrCode
            qrScannerCanvas.style.display = 'none'
            qrScannerVideo.style.display = 'none'
            qrScannerImg.style.display = 'none'
            qrScannerVideoBtnCancel.style.display = 'none'

            currentStream.getTracks().forEach(function(track) {
              track.stop();
            })
          }
        })

  } else {
      return Promise.resolve()
  }
}


function detectImg() {
  detectVideo(false)

  if (qrScannerVideo.srcObject) {
    qrScannerVideo.srcObject.getTracks().forEach(track => track.stop())
    qrScannerVideo.srcObject = null
  }

  setTimeout(() => qrScannerImg.decode().then(() => detect(qrScannerImg)), 100)
}


function detectVideo(active) {
  if (active) {
    detect(qrScannerVideo)
      .then(() => requestId = requestAnimationFrame(() => detectVideo(true)))

  } else {
    cancelAnimationFrame(requestId)
    requestId = null
  }
}


if (qrScannerFileInput) {
  qrScannerFileInput.addEventListener('change', event => {
    qrScannerImg.src = URL.createObjectURL(qrScannerFileInput.files[0])
    qrScannerFileInput.value = null
    detectImg()
  })
}


if (qrScannerImgBtn) {
  qrScannerImgBtn.addEventListener('click', event => {
    qrScannerFileInput.dispatchEvent(new MouseEvent('click'))
  })
}

if (qrScannerVideoBtnCancel) {
  qrScannerVideoBtnCancel.addEventListener('click', event => {
    qrScannerVideo.pause()
    qrScannerVideo.srcObject.getVideoTracks().forEach(track => track.stop())
    qrScannerVideo.srcObject = null
    qrScannerVideoBtnCancel.style.display = 'none'
  })
}

if (qrScannerVideoBtn) {
  qrScannerVideoBtn.addEventListener('click', event => {
    if (!requestId) {
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment'
        }
      }).then(stream => {
        currentStream = stream
        qrScannerCanvas.style.display = 'block'
        qrScannerVideo.style.display = 'block'
        qrScannerImg.style.display = 'block'
        qrScannerVideoBtnCancel.style.display = 'flex'

        qrScannerVideo.srcObject = stream
        detectVideo(true)
      })
      .catch(error => {
          console.error(error)
      })

    } else {
      detectVideo(false)
    }
  })
}
